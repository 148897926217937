import { v4 as uuid } from "uuid";
import { fields } from "./fields";
import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";

export const tableHeaders = [
  headerCheckboxObject(),
  {
    text: "Идентификатор",
    tooltip: "Идентификатор",
    defaultWidth: 170,
    value: fields.GUID,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Код фонда",
    tooltip: "Код фонда",
    defaultWidth: 170,
    value: fields.FUND_CODE,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Наименование",
    tooltip: "Наименование",
    defaultWidth: 350,
    value: fields.TITLE,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Дата создания",
    tooltip: "Дата создания",
    defaultWidth: 280,
    value: fields.CREATE_DATE,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Период",
    tooltip: "Период",
    defaultWidth: 170,
    value: fields.YEAR_PERIOD,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Признак",
    tooltip: "Признак",
    defaultWidth: 180,
    value: fields.KIND,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Статус утверждения",
    tooltip: "Статус утверждения",
    defaultWidth: 280,
    value: fields.APPROVED_STATE,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
];
