import { fields } from "@monorepo/utils/src/variables/projectsData/historicalNotesView/fields";

export const currentFilters = (): Record<string, unknown> => ({
  [fields.GUID]: "",
  [fields.FILTER_PERIOD_FROM]: "",
  [fields.FILTER_PERIOD_TO]: "",
  [fields.FILTER_CREATE_DATE_FROM]: "",
  [fields.FILTER_CREATE_DATE_TO]: "",
  [fields.TITLE]: "",
  [fields.FUND_CODE]: {},
  [fields.APPROVED_STATE]: [],
});
