import { Module } from "vuex";
import { IRootState } from "@monorepo/inventory/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { encodeQueryData, getFullPath, getQuery, checkExistLibrary } from "@monorepo/utils/src/api/utils";
import { convertFiltersCustomToApi } from "@monorepo/inventory/src/views/HistoricalNotesView/utils/convertFiltersToApi";
import { getSelectedIds } from "@monorepo/utils/src/utils/getSelectedIds";
import { IHistoricalNoteElement } from "@monorepo/inventory/src/views/HistoricalNotesView/types/element";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertFiltersToApi, IConvertFiltersToApiResponse } from "@monorepo/utils/src/api/convertFiltersToApi";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { actions as baseActions } from "@monorepo/utils/src/store/modules/actions";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { ISection, Sections } from "@monorepo/utils/src/types/cellsSections";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { IAutocompleteElement } from "@monorepo/utils/src/types/oikAutocompleteType";
import { fields } from "@monorepo/utils/src/variables/projectsData/historicalNotesView/fields";

interface ITableState {
  filters: ITableFiltersObject;
  totalLength: number;
  section?: Sections;
  data: IHistoricalNoteElement[];
  sectionCells: ISection;
  isOpenFilters: boolean;
  autorefresh: boolean;
  searchTemplates: ISearchTemplate[];
  cells: string[];
  infiniteId: string;
  isTableLoading: boolean;
  isLoadingToggleFilters: boolean;
  isLoadingChangeAutorefresh: boolean;
  libraries: {
    kind: Record<string, string>[];
    approvedState: Record<string, string>[];
  };
}

interface DefaultFiltersOptions {
  emptyFieldFilters?: boolean;
}

const defaultFilters = (options: DefaultFiltersOptions = {}): ITableFiltersObject => ({
  sort: {},
  openedId: null,
  isSelectAll: false,
  selectedIds: {},
  fieldFilters: options.emptyFieldFilters ? {} : { [fields.APPROVED_STATE]: ["Согласовано"] },
  initMessagesLength: 50,
});

const getDefaultState = (): ITableState => ({
  filters: defaultFilters(),
  infiniteId: new Date().toString(),
  sectionCells: {} as ISection,
  totalLength: 0,
  searchTemplates: [],
  cells: [],
  section: Sections.HISTORICAL_NOTE,
  data: [],
  isOpenFilters: false,
  autorefresh: false,
  isTableLoading: false,
  isLoadingToggleFilters: false,
  isLoadingChangeAutorefresh: false,
  libraries: {
    kind: [],
    approvedState: [],
  },
});

const resultExportPath = (payload: "PDF" | "CSV" | "XLSX") => {
  switch (payload) {
    case "CSV":
      return QUERY_PATH.GET_HISTORICAL_NOTES_LIST_CSV_LIST;
    case "XLSX":
      return QUERY_PATH.GET_HISTORICAL_NOTES_LIST_XLS_LIST;
    default:
      return QUERY_PATH.GET_HISTORICAL_NOTES_LIST_PDF_LIST;
  }
};

export const historicalNotesView: Module<ITableState, IRootState> = {
  namespaced: true,
  state: getDefaultState,
  mutations: {
    ...baseMutations,
    addSort(state: ITableState, payload: Record<string, SORT_TYPE>) {
      state.filters.sort = { ...payload };
    },
    setFilters(state: ITableState, payload: ReturnType<typeof defaultFilters>) {
      state.filters = payload;
    },
    resetState(state: ITableState) {
      Object.assign(state, getDefaultState());
    },
    clearFilters(state: ITableState, payload: DefaultFiltersOptions) {
      state.filters = defaultFilters(payload);
    },
    addData(
      state: ITableState,
      payload: {
        data: IHistoricalNoteElement[];
        totalLength: number;
        isReset: boolean;
      }
    ) {
      state.data = payload.isReset ? payload.data : state.data.concat(payload.data);
      state.totalLength = payload.totalLength;
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
    addKind(state: ITableState, payload: Record<string, string>[]) {
      state.libraries.kind = payload;
    },
    addApprovedState(state: ITableState, payload: Record<string, string>[]) {
      state.libraries.approvedState = payload;
    },
  },
  actions: {
    ...baseActions,
    async getEventList({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const {
        data,
        total: totalLength,
        error,
      } = await getQuery<IHistoricalNoteElement[]>(QUERY_PATH.GET_HISTORICAL_NOTES_LIST, params).finally(() => {
        commit("setTableLoading", false);
      });
      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0 });
        return { data: state.data || [], totalLength: totalLength || 0 };
      }
      return { data: null, error };
    },
    async getHistoricalNoteElement(info, payload: string) {
      const { data } = await getQuery<IHistoricalNoteElement>(`${QUERY_PATH.GET_HISTORICAL_NOTES_LIST}/${payload}`);
      return data || {};
    },
    async refreshEventList({ commit, state }) {
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const { data, total: totalLength } = await getQuery<IHistoricalNoteElement[]>(QUERY_PATH.GET_HISTORICAL_NOTES_LIST, { ...params, offset: 0 });

      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0, isReset: true });
      }
      return { data: null };
    },
    async getAutocompleteByCode(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.GET_FUND_AUTOCOMPLETE_ITEMS,
        {
          keyword: params,
        },
        false
      );
      if (data !== null) {
        return data.map((item: IAutocompleteElement) => ({
          ...item,
          titleValue: item.isSearchCode ? item.code : item.name,
        }));
      }
      return [];
    },
    async getExportData({ state, dispatch }, payload: "CSV" | "PDF" | "XLSX" | "ZIP") {
      let params: IConvertFiltersToApiResponse | { ids: string[] };
      const selectedKeys = state.filters.selectedIds ? getSelectedIds(state.filters.selectedIds) : [];
      if (selectedKeys.length && !state.filters.isSelectAll) {
        params = {
          ids: selectedKeys,
        };
      } else {
        params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi, true);
      }

      if (payload === "ZIP") {
        await getQuery(QUERY_PATH.GET_HISTORICAL_NOTES_LIST_ZIP_LIST, params);
      } else {
        const queryParams = encodeQueryData(params);
        dispatch("app/openNewWindow", getFullPath(resultExportPath(payload), queryParams), {
          root: true,
        });
      }
    },
    async getKind({ commit, state }) {
      checkExistLibrary(state.libraries.kind, async () => {
        const { data } = await getQuery<string[]>(QUERY_PATH.GET_HISTORICAL_NOTES_KIND);
        commit("addKind", data);
      });
    },
    async getApprovedState({ commit, state }) {
      checkExistLibrary(state.libraries.kind, async () => {
        const { data } = await getQuery<string[]>(QUERY_PATH.GET_HISTORICAL_NOTES_APPROVED_STATE);
        commit("addApprovedState", data);
      });
    },
  },
  getters: {
    ...baseGetters,
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
    kind(state: ITableState) {
      return state.libraries.kind;
    },
    approvedState(state: ITableState) {
      return state.libraries.approvedState;
    },
  },
};
