export interface IHistoricalNotesFields {
  ID: string;
  CREATE_DATE: string;
  UPDATE_DATE: string;
  FUND_CODE: string;
  GUID: string;
  PERIOD: string;
  TITLE: string;
  YEAR_PERIOD: string;
  KIND: string;
  APPROVED_STATE: string;

  FILTER_PERIOD_FROM: string;
  FILTER_PERIOD_TO: string;
  FILTER_CREATE_DATE_FROM: string;
  FILTER_CREATE_DATE_TO: string;
}

export const fields: IHistoricalNotesFields = {
  ID: "id",
  CREATE_DATE: "createDate",
  UPDATE_DATE: "updateDate",
  FUND_CODE: "fundCode",
  GUID: "guid",
  PERIOD: "period",
  TITLE: "title",
  YEAR_PERIOD: "yearPeriod",
  KIND: "kind",
  APPROVED_STATE: "approvedState",

  FILTER_PERIOD_FROM: "periodFrom",
  FILTER_PERIOD_TO: "periodTo",
  FILTER_CREATE_DATE_FROM: "createDateFrom",
  FILTER_CREATE_DATE_TO: "createDateTo",
};
