import { v4 as uuid } from "uuid";
import { fields } from "./fields";

export const modalElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор",
    tooltip: "Идентификатор",
    className: "info-modal__element",
    value: fields.GUID,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Код фонда",
    tooltip: "Код фонда",
    className: "info-modal__element",
    value: fields.FUND_CODE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата создания",
    tooltip: "Дата создания",
    className: "info-modal__element",
    value: fields.CREATE_DATE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Наименование",
    tooltip: "Наименование",
    className: "fund-info-modal__historical-reference info-modal__element_full-size info-modal__element_textarea",
    value: fields.TITLE,
    type: "textarea",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Период",
    tooltip: "Период",
    className: "info-modal__element",
    value: fields.YEAR_PERIOD,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Признак",
    tooltip: "Признак",
    className: "info-modal__element",
    value: fields.KIND,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Статус утверждения",
    tooltip: "Статус утверждения",
    className: "info-modal__element",
    value: fields.APPROVED_STATE,
  },
];
