import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertAutocompleteItemToApi, formDateFilter } from "@monorepo/utils/src/api/convertFiltersToApi";
import { fields } from "@monorepo/utils/src/variables/projectsData/historicalNotesView/fields";

export const convertFiltersCustomToApi = (resultFilters: ITableFiltersObject): void => {
  const yearPeriod = resultFilters.sort?.yearPeriod;
  if (yearPeriod) {
    resultFilters.sort.period = yearPeriod;
    delete resultFilters.sort.yearPeriod;
  }

  formDateFilter(resultFilters, "createDate");
  formDateFilter(resultFilters, "period");
  convertAutocompleteItemToApi(resultFilters, fields.FUND_CODE, `fieldFilters.${fields.FUND_CODE}.code`);
};
