





























import { defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters } from "vuex";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { getFullPath } from "@monorepo/utils/src/api/utils";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { PreviewStatuses } from "@monorepo/inventory/src/constants/previewStatuses";
import InfiniteLoading, { StateChanger } from "vue-infinite-loading";

export default defineComponent({
  props: {
    files: {
      type: Array,
      default() {
        return [];
      },
    },
    fileId: {
      type: [String, Number],
      default() {
        return 0;
      },
    },
    limit: {
      type: Number,
      default() {
        return 100000;
      },
    },
    isShowCloseBtn: {
      type: Boolean,
      default: false,
    },
    getTxtFile: {
      type: Function,
    },
    guidFieldName: {
      type: String,
      default: "uploadFileGuid",
    },
  },
  data() {
    return {
      fileContent: "",
      loadedSize: 0,
      pageSize: 20, // Количество строк на странице
      infiniteId: new Date().toString(),
    };
  },
  watch: {
    fileId: {
      immediate: true,
      handler(value) {
        this.fileContent = "";
        this.loadedSize = 0;
        this.infiniteId = new Date().toString();
        this.$router.replace({ query: { ...(this.$route.query || {}), previewFileId: value } }).catch(console.warn);
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    fileSize() {
      return this.file?.size ? Number(this.file?.size) || 0 : 0;
    },
    previewStatus() {
      return this.file?.previewStatus?.code;
    },
    isSuccessStatus() {
      return this.previewStatus === PreviewStatuses.SUCCESS;
    },
    previewStatusText() {
      switch (this.previewStatus) {
        case PreviewStatuses.IN_PROGRESS:
          return "Создание изображений предпросмотра в работе";
        case PreviewStatuses.FAILED:
          return "Ошибка при создании изображений предпросмотра";
        case PreviewStatuses.DELETED:
          return "Файлы предпросмотра удалены";
        default:
          return "Предпросмотр не поддерживается";
      }
    },
    filePages(): string[] {
      const lines = this.fileContent.split("\n");
      const pages = [];
      let currentPage = "";

      for (let i = 0; i < lines.length; i++) {
        currentPage += lines[i] + "\n";

        if ((i + 1) % this.pageSize === 0 || i === lines.length - 1) {
          pages.push(currentPage);
          currentPage = "";
        }
      }

      return pages;
    },
    file(): any {
      const file = (this.files as unknown as { id: string }[]).find((item: { id: string }) => item.id === this.fileId);
      return file || {};
    },
    isShowExport() {
      return isAuthorityExist(this.user, authorities.EAD_EXPORT);
    },
  },
  methods: {
    //...mapActions("eadView", ["getTxtFile"]),
    ...mapActions("app", ["openNewWindow"]),
    async fetchFile() {
      const guid = this.file?.pages?.[0]?.guid;
      await this.getTxtFile({ uuid: guid, offset: this.loadedSize, limit: this.limit })
        .then((data: string) => {
          this.fileContent = this.fileContent.concat(data);
          this.loadedSize = this.loadedSize + this.limit;
        })
        .catch((error: Error) => {
          console.error("Ошибка при загрузке файла:", error);
        });
    },
    async loadMore($state: StateChanger) {
      if (this.loadedSize >= this.fileSize) {
        $state.complete();
        return;
      }
      await this.fetchFile();
      $state.loaded();
    },
    downloadFile() {
      const guid = this.file?.[this.guidFieldName];
      if (guid) {
        this.openNewWindow(getFullPath(`${QUERY_PATH.GET_FILE_FROM_FILE_STORAGE}?uuid=${guid}&origin=true`));
      }
    },
  },
  async beforeDestroy() {
    const query = Object.assign({}, this.$route.query);
    if (query?.previewFileId) {
      delete query.previewFileId;
      await this.$router.replace({ query: { ...query } });
    }
  },
  components: {
    InfiniteLoading,
  },
});
