

















































import { defineComponent } from "@vue/composition-api";
import { IHistoricalNoteFile } from "@monorepo/inventory/src/views/HistoricalNotesView/types/element";
import { PreviewStatuses } from "@monorepo/inventory/src/constants/previewStatuses";

interface IHeader {
  width: number;
  value: string;
  text: string;
  tooltip?: string;
}

interface IResultData {
  id: number;
  previewStatusCode?: string;
  previewStatusTitle?: string;
  pages?: unknown[];
  type: string;
  title: string;
  uploadFileGuid: string;
  extension: string;
}

export default defineComponent({
  name: "FilesTable",
  props: {
    files: {
      type: Array,
      default() {
        return [];
      },
    },
    id: {
      type: [String, Number],
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      PreviewStatuses,
    };
  },
  computed: {
    headers(): IHeader[] {
      return [
        {
          width: 100,
          value: "type",
          text: "Тип файла",
          tooltip: "Может принимать значения: Не определён, Документ, Спецификация документа, Визуализация документа, Приложение, Подпись, Опись",
        },
        { width: 190, value: "title", text: "Имя файла", tooltip: "Имя файла" },
        { width: 120, value: "extension", text: "Расширение файла", tooltip: "Расширение файла" },
        { width: 120, value: "previewStatusTitle", text: "Статус предпросмотра", tooltip: "Статус предпросмотра" },
      ];
    },
    resultData(): IResultData[] {
      return [...((this.files as IHistoricalNoteFile[]) || [])].map((file: IHistoricalNoteFile, index, arr) => ({
        type: file.type?.title || "",
        uploadFileGuid: file.fileGuid,
        title: file.name,
        extension: file.format?.title || "",
        id: file.id,
        previewStatusCode: file.previewStatus?.code,
        previewStatusTitle: file.previewStatus?.title,
        pages: file.pages,
      }));
    },
  },
  methods: {
    handleOpen(file: IResultData) {
      if (file.id && (file.previewStatusCode || file.pages?.length)) {
        this.$emit("openPreview", file.id);
      }
    },
  },
});
