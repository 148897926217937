import { fields } from "./fields";

export const filtersElements = [
  {
    value: fields.TITLE,
    title: "Наименование",
    tooltip: "Ввод вручную",
    type: "text",
    width: 250,
  },
  {
    value: fields.GUID,
    title: "Идентификатор",
    tooltip: "Ввод вручную",
    type: "text",
    width: 250,
  },
  {
    value: fields.FUND_CODE,
    title: "Код фонда",
    type: "autocomplete",
    showSelectedKey: "code",
    autocompleteActionName: "getAutocompleteByCode",
    width: 268,
  },
  {
    value: fields.CREATE_DATE,
    dateValues: [fields.FILTER_CREATE_DATE_FROM, fields.FILTER_CREATE_DATE_TO],
    title: "Дата создания",
    tooltip: "Дата создания - два календаря",
    type: "datePeriod",
    width: 226,
  },
  {
    title: "Период",
    tooltip: "Период",
    dateValues: [fields.FILTER_PERIOD_FROM, fields.FILTER_PERIOD_TO],
    type: "yearPeriod",
    isRequired: false,
    isCustomWidth: true,
    width: 250,
    choosingInTurn: true,
  },
  {
    value: fields.KIND,
    title: "Признак",
    type: "select",
    selectItemsField: fields.KIND,
    width: 386,
  },
  {
    value: fields.APPROVED_STATE,
    title: "Статус утверждения",
    type: "select",
    selectItemsField: fields.APPROVED_STATE,
    width: 386,
  },
];
