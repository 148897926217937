








































































import { defineComponent } from "@vue/composition-api";
import CardModalInfoElement from "@monorepo/uikit/src/components/tableCardModal/CardModalInfoElement.vue";
import { mapActions, mapGetters } from "vuex";
import { IHistoricalNoteElement } from "@monorepo/inventory/src/views/HistoricalNotesView/types/element";
import { modalElements } from "@monorepo/utils/src/variables/projectsData/historicalNotesView/modalElements";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/historicalNotesView/viewTitle";
import { fields } from "@monorepo/utils/src/variables/projectsData/historicalNotesView/fields";
import { convertApiItemToUi } from "@monorepo/inventory/src/views/HistoricalNotesView/utils/convertApiItemToUi";
import CardModalToggleViewSize from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleViewSize.vue";
import CardModalToggleTableItems from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleTableItems.vue";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import ExportBtn from "@monorepo/uikit/src/components/tableViews/ExportBtn.vue";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import { Route } from "vue-router";
import CardModalChapter from "@monorepo/uikit/src/components/tableCardModal/CardModalChapter.vue";
import useModalChangeByKeypress from "@monorepo/utils/src/composables/useModalChangeByKeypress";
import useUniqueLinkModal from "@monorepo/utils/src/composables/useUniqueLinkModal";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import FilesTable from "@monorepo/inventory/src/views/HistoricalNotesView/components/FilesTable.vue";
import { getFullPath } from "@monorepo/utils/src/api/utils";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import TestImgPreview from "@monorepo/inventory/src/components/TestImgPreview.vue";
import TxtViewer from "@monorepo/inventory/src/components/TxtViewer.vue";
import { getQuery } from "@monorepo/utils/src/api/utils";

export default defineComponent({
  name: "HistoricalNoteInfoModal",
  components: {
    CardModalInfoElement,
    CardModalToggleViewSize,
    CardModalToggleTableItems,
    ExportBtn,
    CardModalChapter,
    FilesTable,
    TxtViewer,
    TestImgPreview,
  },
  props: {
    type: {
      type: String,
      default: ModalType.EDIT,
    },
  },
  data() {
    return {
      modalElements,
      viewUniqKey,
      openedPanels: [0, 1, 2, 3],
      fields,
      section: Sections.HISTORICAL_NOTE,
      cardMode: CardMode,
      element: {} as ReturnType<typeof convertApiItemToUi>,
      isLoading: false,
      isShowPdf: false,
      fileId: null as number | null | string,
    };
  },
  watch: {
    openedId: {
      immediate: true,
      async handler(
        this: {
          $route: Route;
          addQueryOpenedId: () => void;
          getCard: () => void;
          isLoading: boolean;
          isShowAnimation: boolean;
          element: ReturnType<typeof convertApiItemToUi>;
          fileId: number;
          isShowPdf: boolean;
        },
        value
      ) {
        if (value) {
          this.isLoading = true;
          this.isShowPdf = false;
          await this.addQueryOpenedId();
          await this.getCard();
        }
        if (this.$route.query?.previewFileId) {
          const queryFile = this.element?.fileList?.find((item: any) => item.id?.toString() === this.$route.query?.previewFileId);
          if (queryFile && (queryFile.previewStatus?.code || queryFile.pages?.length)) {
            this.fileId = Number(this.$route.query.previewFileId);
            this.isShowPdf = true;
          } else {
            this.isShowPdf = false;
          }
        }
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "isShowAnimation", "cardModeList"]),
    ...mapGetters("historicalNotesView", ["data", "openedId"]),
    cardModeResult(): CardMode {
      return this.cardModeList[this.section as string] || CardMode.DEFAULT;
    },
    file(): { format?: { code: string } } {
      const file = this.element?.fileList?.find((item: any) => item.id === this.fileId);
      return file || {};
    },
  },
  methods: {
    ...mapActions("app", ["openNewWindow"]),
    ...mapActions("historicalNotesView", ["changeOpenedId", "getHistoricalNoteElement", "getScreenshot", "getTxtFile"]),
    async getCard() {
      await this.getHistoricalNoteElement(this.openedId)
        .then((data: IHistoricalNoteElement) => {
          this.element = convertApiItemToUi(data || {});
        })
        .catch(console.error)
        .finally(() => {
          this.isLoading = false;
        });
    },
    openPreview(fileId: number) {
      this.fileId = fileId;
      this.isShowPdf = true;
    },
    async exportFiles() {
      await getQuery(QUERY_PATH.GET_HISTORICAL_NOTES_LIST_ZIP_LIST, { ids: [this.openedId] });
    },
    openExportNote() {
      this.openNewWindow(`${getFullPath(QUERY_PATH.GET_HISTORICAL_NOTES_LIST)}/export/${this.openedId}/pdf`);
    },
    closeModal() {
      this.$emit("close");
    },
  },
  setup(props, context) {
    const { root } = context;
    useModalChangeByKeypress(root, "historicalNotesView", Sections.HISTORICAL_NOTE, props.type);
    const { addQueryOpenedId } = useUniqueLinkModal(root, "historicalNotesView");

    return {
      addQueryOpenedId,
    };
  },
});
