import { fields } from "@monorepo/utils/src/variables/projectsData/historicalNotesView/fields";
import moment from "moment";
import { IHistoricalNoteElement } from "@monorepo/inventory/src/views/HistoricalNotesView/types/element";

export const convertApiItemToUi = (element: IHistoricalNoteElement): any => ({
  ...element,
  [fields.ID]: element.id,
  [fields.PERIOD]: element.period ? moment(element.period).format("YYYY-MM-DD") : "",
  [fields.CREATE_DATE]: element.createDate ? moment(element.createDate).format("YYYY-MM-DD") : "",
  [fields.YEAR_PERIOD]: element.yearPeriod?.join("-"),
  [fields.KIND]: element.kind?.title,
  [fields.APPROVED_STATE]: element.approvedState?.title,
});
