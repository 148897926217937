<template>
  <div class="wrap">
    <v-app-bar color="#323639" dark>
      <v-toolbar-title class="pdf__title ml-3">{{ file && file.title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <div v-if="isSuccessStatus" class="d-flex flex-row align-center">
        <v-btn icon :disabled="currentPage === 1" @click="moveToPage(1)">
          <v-icon size="14">mdi-arrow-collapse-left</v-icon>
        </v-btn>
        <v-btn width="22" height="22" icon :disabled="currentPage === 1" @click="moveToPage(currentPage - 1)">
          <v-icon size="14">mdi-arrow-left</v-icon>
        </v-btn>
        <div class="pdf__white ml-1 mr-1">{{ currentPage }} / {{ numPages }}</div>
        <v-btn width="22" height="22" icon :disabled="currentPage === numPages" @click="moveToPage(currentPage + 1)">
          <v-icon size="14">mdi-arrow-right</v-icon>
        </v-btn>
        <v-btn width="22" height="22" icon :disabled="currentPage === numPages" @click="moveToPage(numPages)">
          <v-icon size="14">mdi-arrow-collapse-right</v-icon>
        </v-btn>
        <v-btn class="ml-6" width="22" height="22" icon @click="scale -= scale > 0 ? 0.1 : 0">
          <v-icon size="18">mdi-minus</v-icon>
        </v-btn>
        <v-text-field outlined dense hide-details class="pdf__scale-input" v-model="scaleInput" :min="1" :max="100"></v-text-field>
        <v-btn width="22" height="22" icon @click="scale += scale < 2 ? 0.1 : 2">
          <v-icon size="18">mdi-plus</v-icon>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <v-btn v-if="isShowExport" :loading="isExportFileLoading" class="mr-1" icon color="white" @click="downloadFile">
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-btn v-if="isShowCloseBtn" class="mr-1" icon color="white" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <div class="pdf-container">
      <div class="pdf-container__thumbnails">
        <div class="thumbnails" v-if="isSuccessStatus">
          <div class="thumbnails__item" v-for="(image, index) in previewImages" :key="index">
            <v-card @click="moveToPage(index + 1)">
              <v-img :class="['v-img', { 'active-img': currentPage === index + 1 }]" :src="image" contain :style="{ height: '80px' }"></v-img>
              <div class="index">{{ index + 1 }}</div>
            </v-card>
          </div>

          <infinite-loading ref="infiniteLoadingThumbs" @infinite="loadMore" :distance="10" :identifier="infiniteId">
            <div slot="no-more"></div>
            <div slot="spinner">
              <v-progress-circular indeterminate :width="2" :size="48" class="mt-6 mb-6" color="white" />
            </div>
          </infinite-loading>
        </div>
      </div>
      <div class="pdf-container__content">
        <div class="pdf-pages" id="pdf-pages" @wheel="changePage($event)" v-if="isSuccessStatus" @contextmenu="preventContextMenu">
          <div
            class="pdf-pages__item"
            :ref="`page-${index + 1}`"
            v-for="(image, index) in images"
            :key="image"
            :style="{ width: `${scale * 100}%`, 'object-fit': 'contain' }"
          >
            <!-- <OrcPage :imageUrl="image.url" hocrText="" /> -->
            <img :src="image" :style="{ width: '100%' }" />
          </div>
          <!-- <OrcPage /> -->
          <infinite-loading ref="infiniteLoading" @infinite="loadMore" :distance="10" :identifier="infiniteId">
            <div slot="no-more"></div>
            <div slot="spinner">
              <v-progress-circular indeterminate :width="2" :size="48" class="mt-6 mb-6" color="white" />
            </div>
          </infinite-loading>
        </div>
        <div v-else class="pdf-container__status d-flex flex-row justify-center align-center">
          <div class="pdf-container__status-text">{{ previewStatusText }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import { mapActions, mapGetters } from "vuex";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { getFullPath } from "@monorepo/utils/src/api/utils";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { PreviewStatuses } from "@monorepo/inventory/src/constants/previewStatuses";
// import OrcPage from "./DocumentsView/OrcPage_documents-view.vue";

export default {
  name: "InfiniteScrollImages",
  props: {
    files: {
      type: Array,
      default() {
        return [];
      },
    },
    fileId: {
      type: [String, Number],
      default() {
        return 0;
      },
    },
    pages: {
      type: Array,
      default() {
        return [];
      },
    },
    isOnlyFile: {
      type: Boolean,
      default: false,
    },
    isShowExport: {
      type: Boolean,
      default: false,
    },
    uploadFileGuid: {
      type: String,
      default: "",
    },
    isShowCloseBtn: {
      type: Boolean,
      default: false,
    },
    getScreenshot: {
      type: Function,
    },
    guidFieldName: {
      type: String,
      default: "uploadFileGuid",
    },
  },
  data() {
    return {
      loadedItems: 0,
      currentPage: 1,
      scale: 0.7,
      resultImages: [],
      resultPreviewImages: [],
      infiniteId: new Date().toString(),
    };
  },
  watch: {
    fileId: {
      immediate: true,
      handler(value) {
        this.resetData();
        if (!this.isOnlyFile) {
          this.$router.replace({ query: { ...(this.$route.query || {}), previewFileId: value } }).catch(console.warn);
        }
      },
    },
    pages: {
      immediate: true,
      handler() {
        this.resetData();
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("app", ["isExportFileLoading"]),
    previewStatus() {
      return this.file?.previewStatus?.code;
    },
    isSuccessStatus() {
      return this.isOnlyFile || this.previewStatus === PreviewStatuses.SUCCESS;
    },
    previewStatusText() {
      switch (this.previewStatus) {
        case PreviewStatuses.IN_PROGRESS:
          return "Создание изображений предпросмотра в работе";
        case PreviewStatuses.FAILED:
          return "Ошибка при создании изображений предпросмотра";
        case PreviewStatuses.DELETED:
          return "Файлы предпросмотра удалены";
        default:
          return "Предпросмотр не поддерживается";
      }
    },
    previewUnsupported() {
      return this.file?.pages[0]?.type === "NO_CONVERTING" || this.file?.pages?.length === 1;
    },
    images() {
      return this.resultImages.slice(0, this.loadedItems) || [];
    },
    previewImages() {
      return this.resultImages.slice(0, this.loadedItems) || [];
    },
    numPages() {
      return this.previewUnsupported ? 1 : (this.file?.pages?.length || 0) / 2;
    },
    file() {
      const file = this.isOnlyFile ? { pages: this.pages } : this.files.find((item) => item.id === this.fileId);
      return file;
    },
    scaleInput: {
      get() {
        return Math.trunc(this.scale * 100);
      },
      set(val) {
        this.scale = +val / 100;
      },
    },
  },
  methods: {
    ...mapActions("app", ["openNewWindow"]),
    preventContextMenu(event) {
      event.preventDefault(); // Предотвращаем появление контекстного меню (правого клика)
    },
    resetData() {
      this.loadedItems = 0;
      this.currentPage = 1;
      this.resultImages = [];
      this.resultPreviewImages = [];
      this.infiniteId = new Date().toString();
    },
    async getFilesScreenshots() {
      const loadingIds = this.file?.pages.slice(this.resultImages.length, this.loadedItems);
      const loadingPreviewIds = this.file?.pages.slice(
        this.resultImages.length + this.numPages,
        this.resultImages.length + this.numPages + this.loadedItems
      );

      const newFiles = await Promise.all(
        loadingIds.map(async (page) => {
          return await this.getScreenshot(page.guid);
        })
      );
      const newPreviewFiles = await Promise.all(
        loadingPreviewIds.map(async (page) => {
          return await this.getScreenshot(page.guid);
        })
      );
      this.resultImages = this.resultImages.concat(newFiles);
      this.resultPreviewImages = this.previewUnsupported ? this.resultImages : this.resultPreviewImages.concat(newPreviewFiles);
    },
    async moveToPage(page) {
      while (page > this.loadedItems) {
        const $state = this.$refs.infiniteLoading.stateChanger;
        await this.loadMore($state);
      }

      this.$refs[`page-${page}`][0].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      this.currentPage = page;
    },
    changePage(event) {
      const scroll = document.getElementById("pdf-pages").scrollTop;

      if (this.numPages === 1) {
        return;
      }

      if (!this.$refs[`page-${this.currentPage}`]?.[0]) {
        return;
      }

      if (this.currentPage !== 1 && scroll <= (this.$refs[`page-${this.currentPage}`]?.[0].offsetTop || 0)) {
        this.currentPage -= 1;
        return;
      }

      if (this.currentPage < this.numPages && scroll > (this.$refs[`page-${this.currentPage + 1}`]?.[0].offsetTop || 0)) {
        this.currentPage += 1;
      }
    },
    async loadMore($state) {
      if (this.loadedItems >= this.numPages) {
        $state.complete();
        return;
      }

      this.loadedItems = this.numPages > this.loadedItems + 10 ? this.loadedItems + 10 : this.numPages;
      await this.getFilesScreenshots();
      $state.loaded();
    },
    downloadFile() {
      const guid = this.isOnlyFile ? this.uploadFileGuid : this.file?.[this.guidFieldName];
      if (guid) {
        this.openNewWindow(getFullPath(`${QUERY_PATH.GET_FILE_FROM_FILE_STORAGE}?uuid=${guid}&origin=true`));
      }
    },
  },
  async beforeDestroy() {
    const query = Object.assign({}, this.$route.query);
    if (query?.previewFileId) {
      delete query.previewFileId;
      await this.$router.replace({ query: { ...query } });
    }
  },
  components: {
    InfiniteLoading,
    // eslint-disable-next-line vue/no-unused-components
    // OrcPage,
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  background: #525659;
}

.pdf-page-buttons {
  background-color: #000;
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  z-index: 100;
}

.pdf-page-buttons__title {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pdf__white {
  color: #fff;
  font-size: 14px;
  margin: 0 10px;
}

.thumbnails {
  overflow-y: auto;
  width: 100%;
  background: #323639;
  height: 100%;
  padding: 5px;

  &__item {
    margin-bottom: 6px;
  }
}

.thumbnails .v-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 70%;
  background: transparent;
  box-shadow: none;
  margin: 0 auto;
}

.thumbnails .v-img {
  height: 100%;
  width: auto;
  opacity: 0.5;

  &.active-img {
    opacity: 1;
  }
}

.index {
  color: #fff;
  font-size: 12px;
  margin-top: 5px;
}

button {
  color: #fff !important;
}

button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

input {
  width: 40px;
  text-align: center;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 14px;
}

input:focus {
  outline: none;
}

.pdf-container {
  width: 100%;
  height: calc(100% - 64px);
  display: flex;

  &__thumbnails {
    width: 200px;
  }

  &__content {
    flex: 1;
  }

  &__status {
    width: 100%;
    height: 100%;
  }

  &__status-text {
    transform: translate(-100px);
    color: white;
  }
}

.pdf-col,
.pdf-row {
  height: 100%;
}

.pdf-pages {
  height: 100%;
  gap: 12px;
  overflow: auto;
  position: relative;

  &__item {
    display: flex;
    justify-content: center;
    margin: 0 auto 12px;
  }
}

.pdf__title {
  font-size: 14px;
}

.pdf__scale-input {
  max-width: 50px;
  font-size: 14px;
}
</style>
